import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Container, Accordion } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import * as styles from "../components/index.module.css"


const IndexPage = () => (
  <Layout>
    <Seo title="FAQs" />
    <section className="page-section">

        <Container>

        <h1>FAQs</h1>

        </Container>
        <Container className="singleCol">
          <Accordion defaultActiveKey="0" className="mb-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Do you service my area?</Accordion.Header>
              <Accordion.Body>
              Western AeroBarrier services most of California, from the San Francisco Bay Area (including Napa & Sonoma) to Lake Tahoe in the north, and throughout the state down south to Los Angeles and San Diego. We also service Nevada in the Lake Tahoe and Las Vegas areas, and in the Yuma area of Arizona. See our <Link to="/contact/">Service Area Map.</Link>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>At what stage of construction is AeroBarrier applied?</Accordion.Header>
              <Accordion.Body>
              AIR BARRIER AT EXTERIOR: AeroBarrier is installed right before insulating unless the insulation is to be spray foam, in which case it is best to install after spray foam.  AIR BARRIER AT DRYWALL:  AeroBarrier is installed at any stage between drywall taping and drywall priming.  
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>If I use AeroBarrier, can I change my insulation from using spray foam?</Accordion.Header>
              <Accordion.Body>
              We are big advocates for spray foam, it provides a far better thermal barrier than do fiberglass batts for example.  As to an air barrier, spray foam does a great job of getting a building fairly air tight, and AeroBarrier works well with it to get the air barrier over the finish line to achieve ultra-tight.  Also, unvented/conditioned attic spaces (including cathedral ceilings and flat roofs) should have spray foam below the roof deck material, or ridged foam on top, in order to mitigate condensation issues which cause rot and mold - and thus spray foam is often required anyway in a lot of today's construction.  
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Can you apply AeroBarrier in an occupied residence/building?</Accordion.Header>
              <Accordion.Body>
              AeroBarrier application is not appropriate for occupied residences/buildings.  We will only apply in new construction or in gut-remodels before any “finishes” are installed. 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Is it safe to breathe during application?</Accordion.Header>
              <Accordion.Body>
              No. During application, if a technician has to enter the space while it is being sealed they wear personal protective equipment (PPE). After the sealing is complete, the area is safe to enter without protective gear within 20 to 30 minutes.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>What is the stuff being sprayed?</Accordion.Header>
              <Accordion.Body>
                <p>The AeroBarrier sealant is  a waterborne acrylic that has been used as a fluid-applied permeable air barrier for many years. The sealant is GREENGUARD Gold certified and has been tested according to various ASTM standards and NFPA 285, for fire spread, smoke production, adhesion, antifungal properties, and tensile strength.</p>
                
                <p>The sealant is low VOC and has no off-gassing.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>How long does it take to apply?</Accordion.Header>
              <Accordion.Body>
                <p>The entire AeroBarrier process, from setup to completion, generally takes approximately 4-hours for a typical single-family home. When targeting reduced envelope leakage levels of Passive House or ZERH, additional time may be required.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>How much does AeroBarrier cost?</Accordion.Header>
              <Accordion.Body>
                <p>Cost is variable and dependent upon several factors, including building size, the phase of construction for installation, how “leaky” the building is to start, and the targeted air tightness value. Once we determine your project specifications, we provide a quote. <em>Note that costs very for AeroBarrier installations dependent upon where you are in the country. For an “expensive state” for materials, labor, and business operational costs, such as California, the cost of AeroBarrier installations is higher than in a state like Texas.</em></p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>What is the lifecycle of the product, or how long does the tight seal last?</Accordion.Header>
              <Accordion.Body>
                <p>Third-party lab testing reveals AeroBarrier withstands a simulated 50-year durability test, with little or no seal degradation.  Click here to see the durability test results.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>What size leaks will this seal?</Accordion.Header>
              <Accordion.Body>
                <p>AeroBarrier seals holes as large as ½”, and as tiny as a human hair. Aerosol sealing is extremely effective at sealing narrow gaps and extremely small holes that are typically not cost-effective or are missed during manual sealing.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>What surfaces need to be protected?</Accordion.Header>
              <Accordion.Body>
                <p>If AeroBarrier is applied at insulation phase or at drywall phase, there in very minimal preparation required. Vertical surfaces like walls and windows require no covering. All designed openings, such as ducts, electrical and plumbing, will  be covered prior to sealing. All finished horizontal surfaces will be covered.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11">
              <Accordion.Header>Can the sealing be performed in cold weather?</Accordion.Header>
              <Accordion.Body>
                <p>The AeroBarrier process requires temperature control and pressurizing the areas to be sealed. Ideal conditions is an outdoor temperature of ≥ 40˚F. Sealing can be done below 40˚F but may require additional steps for the preparation.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header>Is the sealant safe to use inside the home?</Accordion.Header>
              <Accordion.Body>
                <p>Yes, the AeroBarrier sealant is GreenGuard Gold Certified, meaning that it’s not only safe to be used inside a home, but it meets the stricter certification requirements for use in schools and healthcare facilities as well.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="13">
              <Accordion.Header>How long after the sealing process can work resume in the space?</Accordion.Header>
              <Accordion.Body>
              <p>The area needs to be aired out for 30 minutes after the sealing is complete. This is done by opening doors and windows while running the fan. But work can resume in the space as soon as the sealing equipment is removed and 30 min has passed.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="14">
              <Accordion.Header>How is leakage measured?</Accordion.Header>
              <Accordion.Body>
                <p>The AeroBarrier system uses a standard blower door to measure envelope leakage during the process. The blower door calibrated to meet ASTM Standard E779, E1554, CGSB-149.10-M86, EN 13829, ATTMA Technical Standard 1, NFPA 2001, RESNET and USACE.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="15">
              <Accordion.Header>How does it work?</Accordion.Header>
              <Accordion.Body>
                <p>AeroBarrier is an interior applied air sealing system that seals all building envelope leaks up to 1/2″. The waterborne acrylic sealant is applied by pressuring a targeted building area, then spraying the aerosolized sealant into the pressurized space. The sealant is self-guided to the edges of visible and invisible leaks creating a seal, not by packing the gap but by accumulating across the surface of leaks. Designed to deposit in leaks and not on vertical surfaces, the sealant is applied within 60-90 minutes and dries before system cleanup is complete, allowing construction to resume shortly after the process is complete with little to no impact on standard construction schedules. The system measures the envelope leakage in real-time, enabling the system to dial-in specific requirements for air leakage and guarantee the results.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="16">
              <Accordion.Header>What surfaces need to be protected?</Accordion.Header>
              <Accordion.Body>
                <p>If AeroBarrier is applied at rough-in or right after drywall is installed, there is very minimal preparation required. Vertical surfaces like walls and windows do not need to be covered. All designed openings, such as ducts, vents, electrical and plumbing penetrations, need to be covered prior to sealing. All finished horizontal surfaces need to be covered.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="17">
              <Accordion.Header>How much will this tighten the house?</Accordion.Header>
              <Accordion.Body>
                <p>AeroBarrier can meet whatever tightness requirements the space is designed to handle. The AeroBarrier technology provides real-time air tightness readings allowing air tightness to be specified and achieved every time. AeroBarrier can seal houses just tight enough to meet any IECC code requirement or it can seal to levels below the Passive House standard of 0.6 ACH50.</p>
              </Accordion.Body>
            </Accordion.Item>

            

          </Accordion> 

        <p className="text-center">Any Other Questions?</p>
        <p className="text-center pb-5"><Link to="/contact/" className="btn btn-primary">Contact Us</Link></p>

</Container>
        </section>
    
    
  </Layout>
)

export default IndexPage
